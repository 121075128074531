<template>
  <v-data-table
    :fixed-header="false"
    :height="undefined"
    v-if="residentId"
    v-bind="dataTableAttrs"
    :headers="headersShown"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
    @update:items-per-page="handleItemsPerPageUpdated"
  >
    <template v-slot:top>
      <rs-table-title-bar
        class="mt-6"
        v-if="$refs.headSearch"
        title="Araç Plakaları"
        icon=""
        :add-route="can('edit-resident') ? '#' : null"
        @clear-filters="$refs.headSearch.reset"
        @reload="loadList"
        :search.sync="search.query"
        @click:edit="handleEditClick"
        @click:add="handleAddClick"
        :hide-edit="!can('edit-resident')"
        :edit-enabled="selectedItems.length === 1"
        :show-delete="can('edit-resident')"
        :delete-enabled="selectedItems.length > 0"
        @click:delete="handleBatchDeleteClick"
      />

      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDeleteConfirmed"
        @cancelled="$refs.confirmDelete.hide()"
      />

      <VehicleForm
        ref="vehicleForm"
        :resident-id="residentId"
        :id="selectedItems.length === 1 && selectedItems[0].id"
        @saved="loadList"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.prepend="{ headers }">
      <rs-table-head-search
        :headers="headers"
        :search.sync="search"
        ref="headSearch"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.edit="{ item }">
      <a href="#" @click.prevent="handleEditClick(item)">
        <i class="menu-icon mdi mdi-pencil"></i>
      </a>
    </template>
  </v-data-table>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { hasDataTable, hasPermissions } from "@/view/mixins";
import VehicleForm from "./VehicleForm";

export default {
  mixins: [hasDataTable, hasPermissions],
  components: {
    VehicleForm,
  },
  props: {
    residentId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    residentId() {
      this.loadList();
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
  },
  data() {
    return {
      options: {
        sortBy: ["plate_number"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-resident"),
        },
        {
          text: this.$t("labels.car_plate_number"),
          value: "plate_number",
          searchable: "text",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.residentId) {
        return;
      }

      this.isLoading = true;
      this.selectedItems = [];
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      return this.$api
        .query(`residents/${this.residentId}/vehicles`, {
          params,
        })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleAddClick() {
      this.$refs.vehicleForm.show();
    },
    handleEditClick(item) {
      if (item.id) {
        this.$refs.vehicleForm.show(item.id);
      } else {
        this.$refs.vehicleForm.show(this.selectedItems[0].id);
      }
    },
    handleDelete(item) {
      return this.$api
        .delete(`vehicles/${item.id}`)
        .then(() => {
          this.$toast.success("Araç silindi");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleBatchDeleteClick() {
      this.$refs.confirmDelete.show(
        `${this.selectedItems.length} tane aracı silmek istediğinizden emin misiniz?`
      );
    },
    handleDeleteConfirmed() {
      if (!this.selectedItems.length || this.isLoading) {
        return false;
      }

      this.isLoading = true;

      const promises = [];

      for (let index in this.selectedItems) {
        let promise = this.handleDelete(this.selectedItems[index]);
        promises.push(promise);
      }

      Promise.all(promises).finally(() => {
        this.isLoading = false;
        this.$refs.confirmDelete.hide();
        this.loadList();
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
};
</script>
