<template>
  <v-data-table
    :fixed-header="false"
    :height="undefined"
    v-if="residentId"
    v-bind="dataTableAttrs"
    :headers="headersShown"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
    @update:items-per-page="handleItemsPerPageUpdated"
    :show-select="false"
  >
    <template v-slot:top>
      <rs-table-title-bar
        v-if="$refs.headSearch"
        class="mt-6"
        title="Gönderilmiş Bildirimler"
        icon="mdi-card-account-mail-outline"
        @clear-filters="$refs.headSearch.reset"
        @reload="loadList"
        hide-edit
        :search.sync="search.query"
        v-bind="titleBarAttrs"
      />

      <NotificationDetail ref="notificationDetail" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.prepend="{ headers }">
      <rs-table-head-search
        :headers="headers"
        :search.sync="search"
        ref="headSearch"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.created_at="{ item, value }">
      <a href="#" @click.prevent="handleDetailClick(item)">
        <rs-table-cell-date show-time :value="value" />
      </a>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.read_at="{ value }">
      <rs-table-cell-boolean
        :value="!(value === null)"
        :title="value !== null && new Date(value).toLocaleString()"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.text="{ value }">
      <rs-table-cell-string :value="value" :limit="50" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.notification_channels="{ item }">
      {{ item.notification_channels.join(", ") }}
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { hasDataTable, hasExport } from "@/view/mixins";
import NotificationDetail from "./NotificationDetail";

export default {
  mixins: [hasDataTable, hasExport],
  components: {
    NotificationDetail,
  },
  props: {
    residentId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    residentId() {
      this.loadList();
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["notificationChannelListIds", "notificationStatusList"]),
  },
  data() {
    return {
      options: {
        sortBy: ["created_at"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "Bildirim Türü",
          value: "notification_channels",
          searchable: "multiselect",
          options: () => this.notificationChannelListIds,
          width: "200px",
        },
        {
          text: "Metin",
          value: "text",
          searchable: "text",
        },
        {
          text: "Tarih",
          value: "created_at",
          searchable: "date",
        },
        {
          text: "Durum",
          value: "notification_status",
          searchable: "select",
          options: () => this.notificationStatusList,
          width: "120px",
        },
        // {
        //   text: this.$t("labels.is_seen"),
        //   value: "read_at",
        //   searchable: "yesNo",
        //   align: "center",
        // },
      ],
    };
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.resident_id = this.residentId;

      return params;
    },
    loadList() {
      if (this.isLoading || !this.residentId) {
        this.resetTable();
        return;
      }

      this.resetTable();
      this.isLoading = true;

      const params = this.getParams();

      return this.$api
        .query(`residents/${this.residentId}/notifications`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleDetailClick(item) {
      this.$refs.notificationDetail.show(item.id);
    },
  },
  mounted() {
    this.$nextTick(() => this.loadList());
    this.generateExportColumns(this.headers);
  },
};
</script>
