<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">
        <div v-if="originalData.name">
          <small>Sakin</small><br />
          <span>{{ originalData.name }}</span>
        </div>
        <span v-else>Sakin Ekle</span>
      </h3>
      <v-col class="text-right p-0">
        <rs-action
          :to="{ name: 'incomes.residents.show', params: { id: id } }"
          v-if="id && can('edit-house-income')"
          title="Mali işlemlere git"
          icon
        >
          <v-icon>mdi-cash</v-icon>
        </rs-action>

        <rs-action
          :to="{ name: 'definitions.residents.edit', params: { id: id } }"
          v-if="id && can('edit-resident') && disabled"
          title="Düzenle"
          icon
        >
          <v-icon>mdi-pencil</v-icon>
        </rs-action>

        <rs-action
          @click="showActionLogs"
          v-if="id && can('edit-resident')"
          icon
          title="Düzenleme geçmişi"
        >
          <v-icon>mdi-information-variant</v-icon>
        </rs-action>
      </v-col>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text v-if="clusterId || id">
      <v-tabs v-model="selectedTab">
        <v-tab to="#user-info" replace>Kullanıcı Bilgileri</v-tab>
        <v-tab to="#houses" replace :disabled="!id">Bağımsız Bölümler</v-tab>
        <v-tab to="#notifications" replace :disabled="!id">
          Gönderilmiş Bildirimler
        </v-tab>
        <v-tab to="#vehicles" replace :disabled="!id">Araç Plakaları</v-tab>
        <v-tab to="#notes" replace :disabled="!id" v-if="can('see-note')">
          {{ $t("headers.notes_and_reminders") }}
        </v-tab>
        <v-tab
          to="#documents"
          replace
          :disabled="!id"
          v-if="can('see-document')"
        >
          {{ $t("headers.documents") }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item value="user-info" class="pa-4">
          <v-form
            @submit.prevent.stop="handleFormSubmit"
            ref="form"
            :disabled="isLoading || disabled"
          >
            <v-row dense>
              <v-col sm="6">
                <rs-select
                  :label="$t('labels.user_commercial_type')"
                  :rules="[rules.required]"
                  :items="userCommercialTypeList"
                  v-model="formData.user_commercial_type_id"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>

              <v-col sm="6">
                <rs-text-field
                  :label="$t('labels.name')"
                  :rules="[rules.required, rules.maxLength(formData.name, 120)]"
                  v-model="formData.name"
                  :readonly="disabled"
                  :filled="disabled"
                  hide-details="auto"
                />
              </v-col>

              <v-col sm="6">
                <rs-text-field
                  class="identity-number"
                  :label="$t('labels.identity_no')"
                  v-model="formData.identity_number"
                  :rules="[
                    rules.exactLength(formData.identity_number, 11),
                    rules.numbersOnly,
                    isRequired('identity') && formData.citizenship_id === 230
                      ? rules.required
                      : true,
                  ]"
                  maxlength="11"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>

              <v-col sm="6">
                <rs-text-field
                  label="Pasaport Numarası"
                  v-model="formData.passport_no"
                  :rules="[
                    rules.maxLength(formData.passport_no, 150),
                    passportNoRequired,
                  ]"
                  maxlength="150"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>

              <!-- <v-col sm="6">
                <rs-text-field
                  :label="$t('labels.father_name')"
                  v-model="formData.father_name"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col> -->

              <v-col sm="6">
                <rs-select-location
                  :label="$t('labels.city')"
                  v-model="formData.city_id"
                  :readonly="disabled"
                  :filled="disabled"
                  :location-type-id="2"
                  @change="formData.district_id = null"
                />
              </v-col>

              <v-col sm="6">
                <rs-select-location
                  :label="$t('labels.district')"
                  v-model="formData.district_id"
                  :readonly="disabled"
                  :filled="disabled"
                  :location-type-id="3"
                  :parent-id="formData.city_id"
                  :disabled="!formData.city_id"
                />
              </v-col>

              <v-col sm="6">
                <rs-select
                  label="Vatandaşlık"
                  v-model="formData.citizenship_id"
                  :rules="[
                    isRequired('citizenship_id') ? rules.required : true,
                  ]"
                  :items="countryList"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>

              <v-col sm="6">
                <rs-select
                  label="Banka Entegrasyon İş Akış Türü"
                  :items="residentBankIntegrationTypeList"
                  v-model="formData.bank_integration_workflow_id"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>

              <v-col sm="6">
                <rs-select-role
                  label="Kullanıcı Rolleri"
                  role-type="cluster"
                  v-model="formData.role_ids"
                  :rules="[phoneIsRequired]"
                  :readonly="disabled || !formData.phone"
                  :filled="disabled || !formData.phone"
                />
              </v-col>

              <v-col sm="6">
                <rs-text-field
                  :label="$t('labels.blood_type')"
                  v-model="formData.blood_type"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>

              <v-col sm="12">
                <rs-bank-keywords
                  v-model="formData.bank_keywords"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>

              <v-col sm="6" v-if="id">
                <rs-text-field
                  label="Oturum Şekli"
                  :value="residencyStatus"
                  readonly
                  filled
                />
              </v-col>

              <v-col sm="6" v-if="id">
                <rs-text-field
                  label="Kullanıcı Tipi"
                  :value="
                    residencyStatus === 'Oturan Değil' ? 'TYA Dışı' : 'TYA İçi'
                  "
                  readonly
                  filled
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col sm="4">
                <v-switch
                  v-model="formData.is_active"
                  :label="$t('labels.is_active')"
                  hide-details="auto"
                  :readonly="disabled"
                  :filled="disabled"
                ></v-switch>
              </v-col>

              <v-col sm="4">
                <v-switch
                  v-model="formData.send_email"
                  label="E-posta Gönderilsin mi?"
                  hide-details="auto"
                  :readonly="disabled"
                  :filled="disabled"
                ></v-switch>
              </v-col>

              <v-col sm="4">
                <v-switch
                  v-model="formData.send_notice"
                  label="Tebligat Gönderilsin mi?"
                  hide-details="auto"
                  :readonly="disabled"
                  :filled="disabled"
                ></v-switch>
              </v-col>

              <v-col sm="4">
                <v-switch
                  v-model="formData.send_push_notifications"
                  label="Mobil Bildirim Gönderilsin mi?"
                  hide-details="auto"
                  :readonly="disabled"
                  :filled="disabled"
                ></v-switch>
              </v-col>

              <v-col sm="4">
                <v-switch
                  v-model="formData.send_sms"
                  label="SMS Gönderilsin mi?"
                  hide-details="auto"
                  :readonly="disabled"
                  :filled="disabled"
                ></v-switch>
              </v-col>
            </v-row>

            <v-row dense class="mt-10">
              <v-col cols="12" class="pb-0">
                <h3 class="mb-0">İletişim Bilgileri</h3>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col sm="6">
                <rs-tel-field
                  :label="$t('labels.phone')"
                  :rules="[
                    isRequired('phone') ? rules.required : true,
                    formData.phone != ''
                      ? rules.minLength(formData.phone, 7)
                      : null,
                    rules.numbersOnlyWithParam,
                  ]"
                  v-model="formData.phone"
                  :disabled="disabled"
                />
              </v-col>
              <!-- <v-col sm="6">
                <rs-select
                  :label="$t('labels.phone_verification_status')"
                  :items="[]"
                  v-model="formData.phone_verification_status"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col> -->
              <v-col sm="6">
                <rs-tel-field
                  :label="$t('labels.phone_2')"
                  v-model="formData.phone_2"
                  :disabled="disabled"
                  :rules="[
                    formData.phone_2 != ''
                      ? rules.minLength(formData.phone_2, 7)
                      : null,
                    rules.numbersOnlyWithParam,
                  ]"
                />
              </v-col>
              <!-- <v-col sm="6">
                <rs-select
                  :label="$t('labels.phone_verification_status')"
                  :items="[]"
                  v-model="formData.phone_2_verification_status"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col> -->
            </v-row>
            <v-row dense>
              <v-col sm="6">
                <rs-tel-field
                  :label="$t('labels.phone_home')"
                  v-model="formData.phone_home"
                  :disabled="disabled"
                  :rules="[
                    formData.phone_home != ''
                      ? rules.minLength(formData.phone_home, 7)
                      : null,
                    rules.numbersOnlyWithParam,
                  ]"
                />
              </v-col>
              <v-col sm="6">
                <rs-tel-field
                  :label="$t('labels.phone_work')"
                  v-model="formData.phone_work"
                  :disabled="disabled"
                  :rules="[
                    formData.phone_work != ''
                      ? rules.minLength(formData.phone_work, 7)
                      : null,
                    rules.numbersOnlyWithParam,
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col sm="6">
                <rs-text-field
                  :label="$t('labels.email')"
                  :rules="[
                    rules.maxLength(formData.email, 100),
                    isRequired('email') ? rules.required : true,
                  ]"
                  v-model="formData.email"
                  type="email"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col sm="6">
                <rs-text-field
                  :label="$t('labels.email_2')"
                  :rules="[rules.maxLength(formData.email_2, 20)]"
                  v-model="formData.email_2"
                  type="email"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <!-- <v-col sm="3">
                <v-switch
                  v-model="originalData.email_verified"
                  :label="
                    originalData.email_verified
                      ? $t('labels.verified')
                      : $t('labels.not_verified')
                  "
                  hide-details="auto"
                  readonly
                ></v-switch>
              </v-col>
              <v-col
                sm="3"
                class="text-end align-self-center"
                v-if="id && !originalData.email_verified && originalData.email"
              >
                <v-btn color="primary" outlined>Doğrulama Gönder</v-btn>
              </v-col> -->
            </v-row>
            <!-- <v-row dense>
              <v-col sm="3">
                <v-switch
                  v-model="formData.email_2_verified"
                  :label="
                    originalData.email_verified
                      ? $t('labels.verified')
                      : $t('labels.not_verified')
                  "
                  hide-details="auto"
                  readonly
                ></v-switch>
              </v-col>
              <v-col
                sm="3"
                class="text-end align-self-center"
                v-if="
                  id && !originalData.email_2_verified && originalData.email_2
                "
              >
                <v-btn color="primary" outlined>Doğrulama Gönder</v-btn>
              </v-col>
            </v-row> -->
            <v-row dense>
              <v-col sm="6">
                <rs-textarea
                  :label="$t('labels.address_legal')"
                  :rules="[rules.maxLength(formData.address_legal, 500)]"
                  v-model="formData.address_legal"
                  rows="3"
                  no-resize
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col sm="6">
                <rs-textarea
                  :label="$t('labels.address_work')"
                  :rules="[rules.maxLength(formData.address_work, 500)]"
                  v-model="formData.address_work"
                  rows="3"
                  no-resize
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <rs-textarea
                  :label="$t('labels.description')"
                  :rules="[rules.maxLength(formData.description, 5000)]"
                  v-model="formData.description"
                  maxlength="5000"
                  rows="3"
                  no-resize
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
            </v-row>

            <rs-form-buttons
              :isLoading="isLoading"
              @cancel="handleCancelClick"
              @submit="handleFormSubmit"
              v-if="!disabled"
            />
          </v-form>
        </v-tab-item>
        <v-tab-item value="houses" class="pa-4">
          <HouseList :resident-id="id" />
        </v-tab-item>
        <v-tab-item value="notifications" class="pa-4">
          <NotificationList :resident-id="id" />
        </v-tab-item>
        <v-tab-item value="vehicles" class="pa-4">
          <VehicleList :resident-id="id" />
        </v-tab-item>
        <v-tab-item value="notes" class="pa-4">
          <!-- TODO: the notes should be separate based on "mode" -->
          <NoteList
            :allow-add="true"
            :extra-params="{ resident_id: id }"
            :form-subtitle="originalData.name"
          />
        </v-tab-item>
        <v-tab-item value="documents" class="pa-4">
          <!-- TODO: the notes should be separate based on "mode" -->
          <DocumentList :allow-add="true" :extra-params="{ resident_id: id }" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <v-card-text v-else>
      <p>Sakin eklemek için üst menüden bir toplu yaşam alanı seçin.</p>
    </v-card-text>
    <ActionLogList ref="actionLogList" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { hasForm, hasPermissions } from "@/view/mixins";
import { residentBankIntegrationTypeList } from "@/core/data/options";
import ActionLogList from "@/view/content/action-logs/ActionLogList";
import HouseList from "./HouseList.vue";
import NotificationList from "./NotificationList";
import VehicleList from "./VehicleList";
import NoteList from "@/view/content/Notes/NoteList";
import DocumentList from "@/view/content/documents/DocumentList";

export default {
  mixins: [hasForm, hasPermissions],
  components: {
    ActionLogList,
    DocumentList,
    HouseList,
    NoteList,
    NotificationList,
    VehicleList,
  },
  computed: {
    ...mapGetters([
      "userCommercialTypeList",
      "countryList",
      "clusterId",
      "cluster",
    ]),
    residencyStatus() {
      if (!this.originalData || !this.originalData.houses) {
        return null;
      }

      if (this.originalData.houses.length === 0) {
        return "Oturan Değil";
      }

      const list = [];

      for (const index in this.originalData.houses) {
        const item = this.originalData.houses[index];
        let status;

        if (item.pivot.house_user_type_id === 2) {
          status = "Kiracı";
        } else if (item.pivot.house_user_type_id === 1) {
          if (item.pivot.is_staying) {
            status = "Ev Sahibi (Oturan)";
          } else {
            status = "Ev Sahibi (Oturmayan)";
          }
        } else if (item.pivot.house_user_type_id === 3) {
          status = "Misafir";
        }

        if (list.indexOf(status) === -1) {
          list.push(status);
        }
      }

      return list.join(", ");
    },
    passportNoRequired() {
      if (this.formData.citizenship_id === null) {
        return true;
      }

      return this.isRequired("identity") && this.formData.citizenship_id !== 230
        ? this.rules.required
        : true;
    },
  },
  watch: {
    id() {
      this.load();
    },
  },
  data() {
    return {
      residentBankIntegrationTypeList: residentBankIntegrationTypeList,
      selectedTab: "info",
      formData: {
        user_commercial_type_id: 1,
        bank_integration_workflow_id: 1,
        name: null,
        // father_name: null,
        blood_type: null,
        is_active: true,
        phone: null,
        phone_2: null,
        phone_home: null,
        phone_work: null,
        email: null,
        email_2: null,
        address_legal: null,
        address_work: null,
        description: null,
        citizenship_id: null,
        bank_keywords: [],
        send_email: false,
        send_notice: false,
        send_push_notifications: false,
        send_sms: false,
        role_ids: [],
        user: {},
        identity_number: null,
        passport_no: null,
        city_id: null,
        district_id: null,
      },
    };
  },
  methods: {
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    ...mapActions(["updateClusterId"]),
    load() {
      this.isLoading = true;

      return this.$api
        .query(`residents/${this.id}`)
        .then((response) => {
          this.loadData(response);
          this.updateClusterId(this.originalData.cluster_id);

          if (response.data.data.user === null) {
            this.formData.user = {};
          } else {
            if (response.data.data.user.identity_number) {
              this.formData.identity_number =
                response.data.data.user.identity_number;
            }

            if (response.data.data.user.passport_no) {
              this.formData.passport_no = response.data.data.user.passport_no;
            }
          }

          const roleIds = [];

          if (response.data.data.roles) {
            for (const index in response.data.data.roles) {
              roleIds.push(response.data.data.roles[index].id);
            }
          }

          if (roleIds.length) {
            this.formData.role_ids = roleIds;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign(
        {
          cluster_id: this.clusterId,
        },
        this.formData
      );

      if (formData.phone) {
        formData.phone = this.formData.phone.replace(/[^0-9+]/g, "");
      }
      if (formData.phone_2) {
        formData.phone_2 = this.formData.phone_2.replace(/[^0-9+]/g, "");
      }

      if (formData.phone_work) {
        formData.phone_work = this.formData.phone_work.replace(/[^0-9+]/g, "");
      }

      if (formData.phone_home) {
        formData.phone_home = this.formData.phone_home.replace(/[^0-9+]/g, "");
      }

      if (this.id) {
        this.$api
          .put(`residents/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);

            if (response.data.data.user === null) {
              this.formData.user = {};
            }

            const roleIds = [];

            if (response.data.data.roles) {
              for (const index in response.data.data.roles) {
                roleIds.push(response.data.data.roles[index].id);
              }
            }

            if (roleIds.length) {
              this.formData.role_ids = roleIds;
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`clusters/${this.clusterId}/residents`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "definitions.residents.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
    showActionLogs() {
      this.$refs.actionLogList.show("App\\Models\\Resident", this.id);
    },
    isRequired(fieldName) {
      if (
        !this.cluster ||
        !this.cluster.resident_required_fields ||
        !this.cluster.resident_required_fields.length
      ) {
        return false;
      }

      return this.cluster.resident_required_fields.indexOf(fieldName) !== -1;
    },
    phoneIsRequired(value) {
      if (
        this.isRequired("phone") &&
        this.rules.required(value) !== true &&
        this.rules.required(this.formData.phone) !== true
      ) {
        return "Rol seçebilmek için telefon numarası girmelisiniz.";
      }

      return true;
    },
  },
  mounted() {
    if (this.id) {
      this.$nextTick(() => {
        this.load();
      });
    }
  },
};
</script>

<style lang="scss">
.v-input--checkbox {
  label {
    margin-bottom: 0;
  }
}

.identity-number input {
  letter-spacing: 0.3vw;
}
</style>
