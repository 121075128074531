<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Araç Plakası</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row cols="12" dense>
          <v-col sm="12">
            <rs-text-field
              :label="$t('labels.car_plate_number')"
              v-model="formData.plate_number"
              :readonly="disabled"
              :filled="disabled"
              :rules="[
                rules.required,
                rules.maxLength(formData.plate_number, 20),
              ]"
              required
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import hasForm from "@/view/mixins/hasForm";

export default {
  mixins: [hasForm],
  props: {
    residentId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: null,
    };
  },
  methods: {
    show(vehicleId, residentId) {
      this.formData = {
        plate_number: null,
        resident_id: null,
      };

      this.$refs.sidePanel.show();

      if (vehicleId) {
        this.dataId = vehicleId;
        this.load();
      } else {
        this.dataId = null;
        this.formData.resident_id = residentId || this.residentId;
      }
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    load() {
      if (this.isLoading) return false;

      this.isLoading = true;

      this.$api
        .query(`vehicles/${this.dataId}`)
        .then((response) => {
          this.loadData(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);
      if (!this.dataId) {
        formData.cluster_id = this.clusterId;
      }

      if (this.dataId) {
        this.$api
          .put(`vehicles/${this.dataId}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved", response.data.data);
            if (event && event.closeOnSave) {
              this.hide();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`residents/${this.residentId}/vehicles`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$nextTick(() => this.$emit("saved", response.data.data));
            if (event && event.closeOnSave) {
              this.hide();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
