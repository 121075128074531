<template>
  <v-data-table
    v-if="residentId"
    v-bind="dataTableAttrs"
    :headers="headersShown"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
    @update:items-per-page="handleItemsPerPageUpdated"
  >
    <template v-slot:top>
      <rs-table-title-bar
        class="mt-6"
        v-if="$refs.headSearch"
        title="Kullanıcı Bağımsız Bölümleri"
        icon=""
        @clear-filters="$refs.headSearch.reset"
        @reload="loadList"
        :search.sync="search.query"
        :hide-edit="!can('edit-house')"
        :edit-enabled="selectedItems.length === 1"
        @click:edit="handleEditClick"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.prepend="{ headers }">
      <rs-table-head-search
        :headers="headers"
        :search.sync="search"
        ref="headSearch"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.edit="{ item }">
      <router-link
        :to="{
          name: 'definitions.houses.edit',
          params: {
            id: item.house_id,
          },
        }"
      >
        <i class="menu-icon mdi mdi-pencil"></i>
      </router-link>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.entered_on="{ value }">
      <rs-table-cell-date :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.left_on="{ value }">
      <rs-table-cell-date :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.is_staying="{ value }">
      <rs-table-cell-boolean :value="value" />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { hasDataTable, hasPermissions } from "@/view/mixins";

export default {
  name: "HouseList",
  mixins: [hasDataTable, hasPermissions],
  props: {
    residentId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["cluster", "clusterId", "houseUserTypeList"]),
    houseTypeList() {
      return this.cluster && this.cluster.house_type_list;
    },
    blockList() {
      return this.cluster && this.cluster.block_list;
    },
  },
  watch: {
    residentId() {
      this.loadList();
    },
    clusterId() {
      this.loadList();
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
  },
  data() {
    return {
      options: {
        sortBy: ["updated_at"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-company"),
        },
        {
          text: this.$t("labels.house_user_type"),
          value: "house_user_type",
          searchable: "select",
          options: () => this.houseUserTypeList,
          itemText: "name",
        },
        {
          text: "Oturuyor mu?",
          value: "is_staying",
          searchable: "yesNo",
        },
        {
          text: this.$t("labels.door_number"),
          value: "door_number",
          searchable: "text",
        },
        {
          text: this.$t("labels.floor"),
          value: "floor",
          searchable: "text",
        },
        {
          text: this.$t("labels.block"),
          value: "block",
          searchable: "select",
          options: () => this.cluster.blocks,
          itemText: "name",
        },
        {
          text: this.$t("labels.house_type"),
          value: "house_type",
          searchable: "select",
          options: () => this.cluster.house_types,
          itemText: "name",
        },
        {
          text: "Giriş Tarihi",
          value: "entered_on",
          searchable: "date",
        },
        {
          text: "Çıkış Tarihi",
          value: "left_on",
          searchable: "date",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (!this.residentId) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.resident_id = this.residentId;

      return this.$api
        .query(`clusters/${this.clusterId}/house-users`, {
          params,
        })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length === 1) {
        this.$router.push({
          name: "definitions.houses.edit",
          params: {
            id: this.selectedItems[0].house_id,
          },
        });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
};
</script>
