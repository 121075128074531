<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">
      Bildirim<br />
      <!-- <small>{{ originalData.debt_number }}</small> -->
    </template>
    <template slot="body" v-if="originalData.id">
      <v-row dense cols="12">
        <v-col sm="3"><strong>Sakin</strong></v-col>
        <v-col sm="3">
          <router-link
            :to="{
              name: 'definitions.residents.show',
              params: { id: originalData.notifiable_id },
            }"
          >
            {{ originalData.notifiable.name }}
          </router-link>
        </v-col>
        <v-col sm="3"><strong>Gönderim Tarihi</strong></v-col>
        <v-col sm="3">{{
          new Date(originalData.created_at).toLocaleDateString()
        }}</v-col>
      </v-row>

      <v-row dense cols="12">
        <v-col sm="3"><strong>Bildirim Şekli</strong></v-col>
        <v-col sm="3">
          {{ originalData.data.via.join(", ") }}
        </v-col>
        <v-col sm="3"><strong>Görülme Tarihi</strong></v-col>
        <v-col sm="3">
          <template v-if="originalData.read_at">
            {{ new Date(originalData.read_at).toLocaleDateString() }}
          </template>
          <template v-else>
            <em class="text-muted">Görülmedi</em>
          </template>
        </v-col>
      </v-row>

      <v-row dense cols="12">
        <v-col sm="3"><strong>Metin</strong></v-col>
        <v-col sm="9">
          {{ originalData.data.text }}
        </v-col>
      </v-row>

      <rs-form-buttons
        :is-loading="isLoading"
        @cancel="hide()"
        hide-submit
        hide-save-close
      />
    </template>
  </rs-side-panel>
</template>

<script>
import validation from "@/core/validation";

import { hasForm } from "@/view/mixins";

export default {
  mixins: [hasForm],
  data() {
    return {
      id: null,
      isLoading: false,
      rules: validation,
      originalData: {},
      selectedItems: [],
    };
  },
  methods: {
    show(id) {
      this.originalData = {};

      this.id = id;
      this.load();
      this.$refs.sidePanel.show();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    load() {
      if (this.isLoading || !this.id) return false;

      this.isLoading = true;
      this.selectedItems = [];

      this.$api
        .query(`notifications/${this.id}`)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);
        })
        .catch((error) => {
          this.handleError(error);
          this.hide();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
